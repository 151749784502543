import './App.css';
import floatbar_icon1 from "./assets/images/home.269b3d91.png";
import floatbar_icon2 from "./assets/images/Moment.4d06759a.png";
import floatbar_icon3 from "./assets/images/confirm.87c06aa0.png";
import floatbar_icon4 from "./assets/images/Gift1.7cf53395.png";
import floatbar_icon5 from "./assets/images/location.2f8f7b7f.png";
import TN_icon_b6bc069d from "./assets/images/TN_icon.b6bc069d.png";
import icon_wedding_1 from "./assets/images/icon_wedding_1_1.40a2d57a.png";
import icon_wedding_2 from "./assets/images/icon_wedding_2_1.a3d86162.png";
import icon_wedding_3 from "./assets/images/icon_wedding_3_1.bef2ad09.png";
import location_icon from "./assets/images/location_map.b23083c3.png";
import wedding from "./assets/images/wedding.png";
import { useEffect, useRef, useState } from "react";
import WeddingTimer from "./Components/WeddingTimer/Index";
import Sound from "react-sound";
import westlife from "./assets/media/westlife-beautiful-in-white.mp3";
import GalleryComponent from "./Components/GalleryComponent/Index";
import Modal from './Modal';
import GuestAPI from './Models/GuestAPI';
import Swal from "sweetalert2";

function App() {
	const homeRef = useRef(null)
	const albumlRef = useRef(null);
	const inviteRef = useRef(null);
	const directionRef = useRef(null);
	const [isShow, setShow] = useState(false);
	const [isPlaying, setPlaying] = useState(false)
	const [qrCode, setQrCode] = useState("");
	const [guest, setGuest] = useState(null);

	const createHeart = () => {
		const heart = document.createElement('div');
		heart.classList.add('heart');

		heart.style.left = Math.random() * 100 + "vw";
		heart.style.animationDuration = Math.random() * 10 + 5 + "s";

		heart.innerText = '💗';
		document.body.appendChild(heart);

		setTimeout(() => {
			heart.remove();
		}, 4000);
	}

	const handlePlayingMusic = () => {
		setPlaying(!isPlaying);
	}

	useEffect(() => {
		setInterval(createHeart, 800);

		// const params = new URLSearchParams(window.location.search);
		// var qr = params.get('qrCode');
		// setQrCode(qr);
	}, []);

	// useEffect(() => {
	// 	GuestAPI().getGuest(qrCode, (data) => {
	// 		if(data != null) {
	// 			setGuest(data);
	// 			setShow(true);
	// 		} else {
	// 			setShow(false);
	// 		}
	// 	})
	// }, [qrCode])

	const handleXacNhanThamDu = () => {
		GuestAPI().xacNhanThamDu(() => {
			Swal.fire("", "Cảm ơn Quý khách đã tham dự buổi tiệc để chung vui cùng chúng con");
		})
	}

	return (
		<div className="App font-Questrial">
			<div style={{ position: "fixed", right: "10px", top: "20px", fontSize: "20px", cursor: "pointer" }} onClick={handlePlayingMusic}>
				🔊
			</div>
			{/* 96000 */}
			<Sound url={westlife} playStatus={isPlaying ? "PLAYING" : "PAUSED"} autoLoad={true} playFromPosition={1000}></Sound>
			<div class="flex justify-center">
				<nav class="fixed bottom-0 z-50 shadow-2xl shadow-black text-center bg-wedding rounded-md border">
					<ul class="flex flex-row p-2 space-x-4">
						<li class="flex bg-wedding rounded-md cursor-pointer" onClick={() => homeRef.current.scrollIntoView()}>
							<div>
								<div class="flex justify-center">
									<img src={floatbar_icon1} class="w-8 h-8 justify-items-center" alt="" />
								</div>
								<span>Home</span>
							</div>
						</li>
						<li class="flex bg-wedding rounded-md cursor-pointer" onClick={() => albumlRef.current.scrollIntoView()}>
							<div>
								<div class="flex justify-center">
									<img src={floatbar_icon2} class="w-8 h-8 justify-items-center" alt="" />
								</div>
								<span>Album Ảnh</span>
							</div>
						</li>
						<li class="flex bg-wedding rounded-md cursor-pointer" onClick={() => inviteRef.current.scrollIntoView()}>
							<div>
								<div class="flex justify-center">
									<img src={floatbar_icon3} class="w-8 h-8 justify-items-center" alt="" />
								</div>
								<span>Tham dự</span>
							</div>
						</li>
						{/* <li class="flex bg-wedding rounded-md cursor-pointer" onClick={() => inviteRef.current.scrollIntoView()}>
							<div>
								<div class="flex justify-center">
									<img src={floatbar_icon4} class="w-8 h-8 justify-items-center" alt="" />
								</div>
								<span>Mừng cưới</span>
							</div>
						</li> */}
						<li class="flex bg-wedding rounded-md cursor-pointer" onClick={() => directionRef.current.scrollIntoView()}>
							<div>
								<div class="flex justify-center">
									<img src={floatbar_icon5} class="w-6 h-8 justify-items-center" alt="" />
								</div>
								<span>Đường đi</span>
							</div>
						</li>
					</ul>
				</nav>
			</div>
			{/* name */}
			<div id="home" ref={homeRef} class="text-center font-GreatVibes lg:text-7xl text-4xl text-slate-500 mt-10"> Hồng Phi &amp; Bảo Như </div>
			<div class="flex items-center justify-center">
				<img src={TN_icon_b6bc069d} class="w-64 h-40" alt="" />
				<img src={wedding} class="w-64 h-40" alt="" style={{ display: "none" }} />
			</div>
			<div class="text-center font-GreatVibes" style={{ marginTop: "20px" }}>We said YES!!!</div>
			<WeddingTimer />
			{/* ket thuc gio */}
			{/* Bắt đầu trái tim */}
			<div class="flex flex-row items-center justify-center text-slate-700 pt-4 pb-4 space-x-1">
				<div class="lg:w-48 w-24 border-b border-gray-400 mb-2 flex justify-center border-dashed">
					<span class="h-0.5 bg-transparent border-gray-400 w-full"></span>
				</div>
				<svg class="svg-inline--fa fa-heart" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path class="" fill="currentColor" d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"></path>
				</svg>
				<div class="lg:w-48 w-24 border-b border-gray-400 mb-2 flex justify-center border-dashed">
					<span class="h-0.5 bg-transparent border-gray-400 w-full"></span>
				</div>
			</div>
			{/* Kết thúc trái tim */}
			<div class="text-center mt-8 w-3/4 m-auto mb-2"> Thân mời bạn cùng gia đình đến dự hôn lễ của chúng mình! </div>
			{/* Bắt đàu giới thiệu cô dâu chu rể */}
			<div class="flex lg:flex-row flex-col justify-center">
				<div class="lg:hidden w-1/12"></div>
				<div class="lg:w-2/12 w-full flex flex-col justify-items-center items-center" style={{ justifyContent: "center" }}>
					<div class="text-slate-700 mb-2 mt-10">CHÚ RỂ</div>
					<div class="w-full max-w-[18rem]">
						<div class="w-full border-t border-gray-400 mb-2"></div>
					</div>
					<div class="font-bold text-xl text-center mb-2">Hồng Phi</div>
					<div class="text-center text-slate-700 max-w-[18rem]"> "Cuộc sống là những trải nghiệm đáng nhớ và đầy cảm xúc. Kể từ bây giờ anh đã có em để cùng sẻ chia." </div>
					<div class="text-center text-slate-700">♥ ♥ ♥</div>
				</div>
				<div class="lg:w-6/12 w-full" style={{ display: "flex", justifyContent: "center" }}>
					<img src={process.env.REACT_APP_SERVER_URL + "/static/media/TRNG9627_introduction.JPG"} alt="Ảnh cưới phi như" class=" object-cover" />
				</div>
				<div class="lg:w-2/12 w-full flex flex-col justify-items-center items-center" style={{ justifyContent: "center" }}>
					<div class="text-slate-700 mb-2 mt-10">CÔ DÂU</div>
					<div class="w-full max-w-[18rem]">
						<div class="w-full border-t border-gray-400 mb-2"></div>
					</div>
					<div class="font-bold text-xl text-center mb-2">Bảo Như</div>
					<div class="text-slate-700 max-w-[18rem]"> Duyên phận là một điều vô cùng thú vị, đến một lúc nào đó, tất cả những bối rối trong cuộc đời sẽ có câu trả lời, đó sẽ là lúc mọi chuyện lại về đúng chỗ của nó. Những người sinh ra dành cho nhau, rồi sẽ nhìn thấy nhau và nắm lấy nhau trong cuộc đời này. </div>
					<div class="text-slate-700">♥ ♥ ♥</div>
				</div>
				<div class="lg:hidden w-1/12"></div>
			</div>
			{/* Kết thúc giới thiệu cô dâu chu rể */}
			{/* Bắt đầu giới thiệu thời gian cưới */}
			<div class="flex flex-col text-center font-medium gap-5 space-y-2">
				<span>TRÂN TRỌNG KÍNH MỜI</span>
				<span class="font-GreatVibes text-red-700 text-2xl">Bạn và Gia Đình !</span>
				<span>TỚI CHUNG VUI CÙNG GIA ĐÌNH CHÚNG MÌNH</span>
				<div class="text-center font-GreatVibes lg:text-7xl text-4xl text-slate-500 mt-8"> Hồng Phi &amp; Bảo Như </div>
				<div class="text-xl"><b>Lễ Tân Hôn</b>, Vào lúc 11 giờ 00</div>
				<div class="flex justify-center items-center">
					<div class="flex flex-col font-semibold pr-4 pl-4">
						<span>THÁNG</span>
						<span>01</span>
					</div>
					<div class="flex flex-col border-r-2 border-l-2 border-slate-700 font-semibold pr-4 pl-4">
						<span>CHỦ NHẬT</span>
						<span class="font-semibold text-5xl">07</span>
					</div>
					<div class="flex flex-col p-4 font-semibold pr-4 pl-4">
						<span>NĂM</span>
						<span>2024</span>
					</div>
				</div>
				<div>Hôn lễ được cử hành tại Trung Tâm Hội Nghị Tiệc Cưới</div>
				<div class="font-semibold text-2xl">MONAMI</div>
				<div style={{display: "flex", justifyContent: "center", width: "100%"}}>
					<hr style={{width: "50%", textAlign: "center"}}/>
				</div>
				<div class="text-xl"><b>Lễ Vu Quy</b>, Vào lúc 11 giờ 00</div>
				<div class="flex justify-center items-center">
					<div class="flex flex-col font-semibold pr-4 pl-4">
						<span>THÁNG</span>
						<span>01</span>
					</div>
					<div class="flex flex-col border-r-2 border-l-2 border-slate-700 font-semibold pr-4 pl-4">
						<span>THỨ BA</span>
						<span class="font-semibold text-5xl">02</span>
					</div>
					<div class="flex flex-col p-4 font-semibold pr-4 pl-4">
						<span>NĂM</span>
						<span>2024</span>
					</div>
				</div>
				<div>Hôn lễ được cử hành tại </div>
				<div class="font-semibold text-2xl">Tư Gia</div>
				<div class="text-center w-3/4 m-auto">Sự hiện diện của bạn là niềm vinh hạnh của chúng mình !</div>
			</div>
			{/* Kết thúc giới thiệu thời gian cưới */}
			{/* Bắt đầu trái tim */}
			<div class="flex flex-row items-center justify-center text-slate-700 pt-4 pb-4 space-x-1">
				<div class="lg:w-48 w-24 border-b border-gray-400 mb-2 flex justify-center border-dashed">
					<span class="h-0.5 bg-transparent border-gray-400 w-full"></span>
				</div>
				<svg class="svg-inline--fa fa-heart" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path class="" fill="currentColor" d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"></path>
				</svg>
				<div class="lg:w-48 w-24 border-b border-gray-400 mb-2 flex justify-center border-dashed">
					<span class="h-0.5 bg-transparent border-gray-400 w-full"></span>
				</div>
			</div>
			{/* Kết thúc trái tim */}
			{/* băt đầu album ảnh */}
			<div id="album" ref={albumlRef} class="flex flex-col momentImage items-center text-center">
				<span id="sectionMoment" class="text-3xl uppercase font-semibold">KHOẢNH KHẮC ĐẸP</span>
				<span class="text-center w-3/4 m-auto">Cùng ngắm nhìn những bức hình đẹp nhất của chúng mình nhé</span>
				<GalleryComponent></GalleryComponent>
			</div>
			{/* kết thúc album ảnh */}
			{/* Bắt đầu trái tim */}
			<div class="flex flex-row items-center justify-center text-slate-700 pt-4 pb-4 space-x-1">
				<div class="lg:w-48 w-24 border-b border-gray-400 mb-2 flex justify-center border-dashed">
					<span class="h-0.5 bg-transparent border-gray-400 w-full"></span>
				</div>
				<svg class="svg-inline--fa fa-heart" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path class="" fill="currentColor" d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"></path>
				</svg>
				<div class="lg:w-48 w-24 border-b border-gray-400 mb-2 flex justify-center border-dashed">
					<span class="h-0.5 bg-transparent border-gray-400 w-full"></span>
				</div>
			</div>
			{/* Kết thúc trái tim */}
			{/* Bắt đầu sự kiện quan trọng */}
			<div id="sectionLocation" ref={directionRef} class="flex text-3xl uppercase font-semibold text-center justify-center"> SỰ KIỆN QUAN TRỌNG </div>
			<div class="grid lg:grid-cols-7 text-center gap-1">
				<div></div>
				<div></div>
				<div class="space-y-2">
					<div class="flex justify-center">
						<img src={icon_wedding_2} class="lg:w-24 lg:h-24 w-36 h-36 justify-items-center" alt="" />
					</div>
					<p class="font-semibold">LỄ RƯỚC DÂU</p>
					<div class="flex flex-row space-x-1 text-center items-center justify-center">
						<svg class="svg-inline--fa fa-clock" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path class="" fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
						</svg>
						<p>09:00 AM 02/01/2024</p>
					</div>
					<p>Tuy Phước - Bình Định</p>
					<a href="https://goo.gl/maps/JjzwtG7reCatan5QA" target="_blank" class="p-1 flex justify-center" rel="noreferrer">
						<div class="flex items-center justify-center border border-slate-500 rounded-md cursor-pointer p-2 space-x-1 bg-slate-500 text-white">
							<img src={location_icon} class="w-6 h-6 object-cover" alt="" />
							<span>Xem địa chỉ</span>
						</div>
					</a>
				</div>
				<div class="space-y-2">
					<div class="flex justify-center">
						<img src={icon_wedding_1} class="lg:w-24 lg:h-24 w-36 h-36 justify-items-center" alt="" />
					</div>
					<p class="font-semibold">LỄ VU QUY</p>
					<div class="flex flex-row space-x-1 text-center items-center justify-center mt-2">
						<svg class="svg-inline--fa fa-clock" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path class="" fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
						</svg>
						<p>11:00 AM 02/01/2024</p>
					</div>
					<p class="">Tuy Phước - Bình định</p>
					<a href="https://goo.gl/maps/JjzwtG7reCatan5QA" target="_blank" class="p-1 flex justify-center" rel="noreferrer">
						<div class="flex items-center justify-center w-fit border border-slate-500 rounded-md cursor-pointer p-2 space-x-1 bg-slate-500 text-white">
							<img src={location_icon} class="w-6 h-6 object-cover" alt="" />
							<span>Xem địa chỉ</span>
						</div>
					</a>
				</div>
				<div class="space-y-2">
					<div class="flex justify-center">
						<img src={icon_wedding_3} class="lg:w-24 lg:h-24 w-36 h-36 justify-items-center" alt="" />
					</div>
					<p class="font-semibold whitespace-nowrap">LỄ TÂN HÔN</p>
					<div class="flex flex-row space-x-1 text-center items-center justify-center">
						<svg class="svg-inline--fa fa-clock" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path class="" fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
						</svg>
						<p>11:00 AM 07/01/2024</p>
					</div>
					<p>Tân Uyên - Bình Dương</p>
					<a href="https://maps.app.goo.gl/CmZV43PSBedWZ55K7" target="_blank" class="p-1 flex justify-center" rel="noreferrer">
						<div class="flex items-center justify-center border border-slate-500 rounded-md cursor-pointer p-2 space-x-1 bg-slate-500 text-white">
							<img src={location_icon} class="w-6 h-6 object-cover" alt="" />
							<span>Xem địa chỉ</span>
						</div>
					</a>
				</div>
				<div>
				</div>
				<div
				></div>
			</div>
			{/* Kết thúc sự kiện quan trọng */}
			{/* Bắt đầu trái tim */}
			<div class="flex flex-row items-center justify-center text-slate-700 pt-4 pb-4 space-x-1">
				<div class="lg:w-48 w-24 border-b border-gray-400 mb-2 flex justify-center border-dashed">
					<span class="h-0.5 bg-transparent border-gray-400 w-full"></span>
				</div>
				<svg class="svg-inline--fa fa-heart" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
					<path class="" fill="currentColor" d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"></path>
				</svg>
				<div class="lg:w-48 w-24 border-b border-gray-400 mb-2 flex justify-center border-dashed">
					<span class="h-0.5 bg-transparent border-gray-400 w-full"></span>
				</div>
			</div>
			{/* Kết thúc trái tim */}
			{/* Bat dau ban se den chu */}
			<div id="sectionC" ref={inviteRef} class="font-semibold mb-2 flex justify-center text-3xl"> BẠN SẼ ĐẾN CHỨ ? </div>
			{/* <div class="flex justify-center">
				<img src="/img/khung_hinh2.883ea3a5.png" class="lg:w-4/12 w-full object-cover" alt="" />
			</div> */}
			<div class="flex lg:flex-row flex-col lg:space-x-8 justify-center items-center m-4">
				<div id="sectionConfirm" onClick={handleXacNhanThamDu} style={{ textAlign: "center" }} class="bg-red-700 hover:bg-red-500 text-white font-bold py-2 px-4 rounded cursor-pointer lg:w-64 w-full mb-2">
					<svg class="svg-inline--fa fa-heart" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<path class="" fill="currentColor" d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"></path>
					</svg>
					<span style={{ paddingLeft: "10px" }}>XÁC NHẬN THAM DỰ</span>
				</div>
				{/* <div id="sectionGift" style={{ textAlign: "center" }} class="bg-red-700 hover:bg-red-500 text-white font-bold py-2 px-4 rounded cursor-pointer lg:w-64 w-full mb-2">
					<svg class="svg-inline--fa fa-gift" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gift" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<path class="" fill="currentColor" d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z">
						</path>
					</svg>
					<span style={{ paddingLeft: "10px" }}>MỪNG CƯỚI</span>
				</div> */}
			</div>
			<div class="flex text-center justify-center">
				<div class="lg:w-1/2">Đám cưới của chúng mình sẽ trọn vẹn hơn khi có thêm lời chúc phúc và sự hiện diện của các bạn. Đừng quên cuộc hẹn với chúng mình bạn nhé!</div>
			</div>
			<div class="font-GreatVibes text-6xl pb-28 text-slate-500 text-center space-y-2"> Thank you!</div>
			{/* Ket thuc ban se den chu */}
			{/* <XacNhanThamDu></XacNhanThamDu> */}
			<Modal isShow={isShow} setShow={setShow} data={guest}></Modal>
		</div>
	);
}

export default App;
