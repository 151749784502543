import { useEffect, useState } from "react";
import moment from "moment";

function WeddingTimer() {
	const [day, setDay] = useState({ Date: 0, Hour: 0, Minute: 0, Second: 0 });

	useEffect(() => {
		setInterval(() => {
			let duration = new Date(2024, 0, 2).getTime() - new Date().getTime()
			let seconds = duration / 1000;
			let minutes = seconds / 60;
			let hours = minutes / 60;
			let days = hours / 24;
			hours = days * 24 % 24;
			minutes = hours * 60 % 60
			seconds =minutes * 60 % 60
			setDay({ Date: Math.floor(days), Hour: Math.floor(hours), Minute: Math.floor(minutes), Second: Math.floor(seconds) })
		}, 1000);
	}, [])


	return (
		<div className="flex items-center justify-center" style={{ marginTop: "50px" }}>
			<div className="flex flex-row gap-6 md:text-6xl text-3xl">
				<div class="bg-slate-500 text-white rounded-full flex flex-col items-center justify-center md:w-28 w-16 h-16 md:h-28">
					<div>{day.Date}</div>
					<div class="text-sm">Ngày</div>
				</div>
				<div class="bg-slate-500 text-white rounded-full flex flex-col items-center justify-center w-16 h-16 md:w-28 md:h-28">
					<div>{day.Hour}</div>
					<div class="text-sm">Giờ</div>
				</div>
				<div class="bg-slate-500 text-white rounded-full flex flex-col items-center justify-center w-16 h-16 md:w-28 md:h-28">
					<div>{day.Minute}</div>
					<div class="text-sm">Phút</div>
				</div>
				<div class="bg-slate-500 text-white rounded-full flex flex-col items-center justify-center w-16 h-16 md:w-28 md:h-28">
					<div>{day.Second}</div>
					<div class="text-sm">Giây</div>
				</div>
			</div>
		</div>
	)
}

export default WeddingTimer;