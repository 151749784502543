import { useEffect, useState } from "react";
import GalleryAPI from "../../Models/GalleryAPI";
import ImageGallery from "react-image-grid-gallery";
function GalleryComponent() {
    const [gallery, setGallery] = useState([])
    const [images, setImages] = useState([]);

    useEffect(() => {
        GalleryAPI().GetGallery(data => {
            setGallery(data);
        })
    }, []);

    useEffect(() => {
        if (gallery == null || gallery.length <= 0)
            return;
        let temp = [];
        gallery.forEach(e => {
            temp.push({
                src: process.env.REACT_APP_SERVER_URL + e.url
            });
        })
        setImages(temp);
    }, [gallery, setImages])

    return (
        <div className="lg:grid-cols-6 grid-cols-2 gap-2 m-2 w-3/4">
            <ImageGallery imgArray={images} columnWidth={230} gapSize={24} />
        </div>
    );
}

export default GalleryComponent;