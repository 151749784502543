import BaseAPI from "./BaseAPI";

function GetGallery(onsuccess) {
    BaseAPI().Get("galleries", data => {
        onsuccess(data);
    }, error => {
        console.log(error)
    })
}

function GalleryAPI() {
    return {
        GetGallery: GetGallery
    }
}

export default GalleryAPI;